import type { CSSProperties, ReactElement } from 'react';

import loadable from '@loadable/component';
import classNames from 'classnames';

import { BpkContentBubble } from '@skyscanner/backpack-web/bpk-component-flare';
import BpkText, {
  TEXT_STYLES,
} from '@skyscanner/backpack-web/bpk-component-text';

import { PRODUCT_GROUP } from '../../constants';
import FlightsSearchControlsGCWrapper from '../FlightsSearchControlsGCWrapper';
import FlightsSearchControlsOC from '../FlightsSearchControlsOC';

import HotelsPropsContext from './HotelsPropsContext';

import type { SearchControlsProps } from '@skyscanner-internal/falcon-shared-types/types/CarHireSearchControlsProps';
import type { FlightsSearchControlsGCWrapperProps } from '@skyscanner-internal/falcon-shared-types/types/FlightsSearchControlsGCWrapperProps';
import type { SearchControlsWrapperProps } from '@skyscanner-internal/falcon-shared-types/types/SearchControlsWrapper';
import type { Vertical } from '@skyscanner-internal/falcon-shared-types/types/Vertical';
import type { HotelsSearchControlsWrapperProps } from '@skyscanner-internal/falcon-shared-types/types/hotels-components/HotelSearchControlsWrapperProps';
import type { HotelsSearchControlsGCWrapperProps } from '@skyscanner-internal/falcon-shared-types/types/hotels-components/HotelsSearchControlsGCWrapperProps';

import STYLES from './SearchControlsWrapper.module.scss';

const searchControlsMap = {
  [PRODUCT_GROUP.HOTELS]: loadable(
    () => import('../HotelSearchControlsWrapper'),
  ),
  [PRODUCT_GROUP.CAR_HIRE]: loadable(() => import('../CarHireSearchControls')),
};

const SearchControls = (
  vertical: Vertical,
  useDeferOC?: boolean,
  carHireProps?: SearchControlsProps,
  hotelsProps?: HotelsSearchControlsWrapperProps,
  hotelsGCProps?: HotelsSearchControlsGCWrapperProps,
  flightsHtmlContent?: string,
  background?: string,
  useFlightsGC?: boolean,
  flightsGCProps?: FlightsSearchControlsGCWrapperProps,
) => {
  const searchWrapperClasses = [STYLES.SearchWrapper];

  if (background) {
    searchWrapperClasses.push(STYLES.SearchWrapperLarge);
  }

  switch (vertical) {
    case PRODUCT_GROUP.FLIGHTS: {
      if (useFlightsGC) {
        if (!flightsGCProps) {
          throw Error(
            'Vertical is flights and useFlightsGC is true but flightsGCProps is empty',
          );
        }

        return (
          <div
            data-testid="flights-search-controls-gc"
            className={classNames(searchWrapperClasses)}
          >
            <FlightsSearchControlsGCWrapper {...flightsGCProps} />
          </div>
        );
      }

      if (!flightsHtmlContent) {
        throw Error('Vertical is flights but flightsHtmlContent is empty');
      }

      return (
        <FlightsSearchControlsOC
          searchWrapperClasses={searchWrapperClasses}
          useDeferOC={useDeferOC}
          flightsHtmlContent={flightsHtmlContent}
        />
      );
    }
    case PRODUCT_GROUP.HOTELS: {
      if (hotelsProps) {
        const VerticalSearchControls = searchControlsMap[vertical];
        return (
          <div
            data-testid="hotels-search-controls"
            className={STYLES.HotelsSearchWrapper}
          >
            <HotelsPropsContext.Provider value={hotelsProps}>
              <VerticalSearchControls {...hotelsProps} />
            </HotelsPropsContext.Provider>
          </div>
        );
      }

      if (hotelsGCProps) {
        const VerticalSearchControls = loadable(
          () => import('../HotelsSearchControlsGCWrapper'),
        );
        return (
          <div
            data-testid="hotels-search-controls-gc"
            className={STYLES.HotelsSearchControlsGCWrapper}
          >
            <VerticalSearchControls {...hotelsGCProps} />
          </div>
        );
      }

      throw Error(
        'Vertical is Hotels but both hotelsProps and hotelsGCProps are empty',
      );
    }
    case PRODUCT_GROUP.CAR_HIRE: {
      if (!carHireProps) {
        throw Error('Vertical is CarHire but carHireProps is empty');
      }
      const VerticalSearchControls = searchControlsMap[vertical];
      return (
        <div
          data-testid="car-hire-search-controls"
          className={classNames(searchWrapperClasses)}
        >
          <VerticalSearchControls {...carHireProps} />
        </div>
      );
    }
    default: {
      throw Error(`SearchControlsWrapper: Unrecognized vertical: ${vertical}`);
    }
  }
};

const SearchControlsWrapper = ({
  background,
  carHireProps,
  flightsGCProps,
  flightsHtmlContent,
  h1,
  hotelsGCProps,
  hotelsProps,
  isMainTitle = true,
  layout = 'text',
  showFlare = false,
  useDeferOC,
  useFlightsGC,
  vertical,
}: SearchControlsWrapperProps) => {
  const innerContentClasses = [STYLES.InnerContent];

  if (background) {
    innerContentClasses.push(STYLES.InnerContentLarge);
  }

  let InnerElement: ReactElement = <div />;

  if (layout === 'bubble' || layout === 'bubbleCentre') {
    InnerElement = (
      <div
        className={classNames(STYLES.Content, STYLES.BubbleContent, {
          [STYLES.BubbleCentreContent]: layout === 'bubbleCentre',
        })}
      >
        <div
          style={{ ['--bg-image' as keyof CSSProperties]: background }}
          className={STYLES.BubbleImage}
        />
        <BpkText
          className={STYLES.Title}
          tagName={isMainTitle ? 'h1' : 'h2'}
          textStyle={TEXT_STYLES.heading2}
        >
          {h1}
        </BpkText>
        {SearchControls(
          vertical,
          useDeferOC,
          carHireProps,
          hotelsProps,
          hotelsGCProps,
          flightsHtmlContent,
          background,
          useFlightsGC,
          flightsGCProps,
        )}
      </div>
    );
  }
  if (layout === 'text') {
    InnerElement = (
      <div className={STYLES.Content}>
        <BpkText
          className={STYLES.Title}
          tagName={isMainTitle ? 'h1' : 'h2'}
          textStyle={background ? TEXT_STYLES.hero4 : TEXT_STYLES.heading1}
        >
          {h1}
        </BpkText>
        {SearchControls(
          vertical,
          useDeferOC,
          carHireProps,
          hotelsProps,
          hotelsGCProps,
          flightsHtmlContent,
          background,
          useFlightsGC,
          flightsGCProps,
        )}
      </div>
    );
  }

  return (
    <BpkContentBubble
      style={
        background
          ? {
              backgroundImage: background,
            }
          : {}
      }
      className={STYLES.Hero}
      contentClassName={innerContentClasses.join(' ')}
      showPointer={showFlare}
      rounded={false}
      content={InnerElement}
      flareProps={{ className: STYLES.Flare, 'aria-hidden': true }}
    />
  );
};

export default SearchControlsWrapper;
