import FlightsSearchControlsGC, {
  navigate,
  type SearchParams,
} from '@skyscanner-internal/global-components/flights-search-controls';
import {
  fetchCalendarPrices,
  fetchMonthPrices,
  fetchSuggestions,
} from '@skyscanner-internal/global-components/flights-search-controls/data';
import logger from 'saddlebag-logger';

import { getIsClient } from './helpers';

import type { FlightsSearchControlsGCWrapperProps } from '@skyscanner-internal/falcon-shared-types/types/FlightsSearchControlsGCWrapperProps';

const FlightsSearchControlsGCWrapper = ({
  cldr,
  culture,
  deviceType,
  origin,
  pageType,
  strings,
}: FlightsSearchControlsGCWrapperProps) => {
  const isClient = getIsClient();
  // This should come form users own location / request context?
  // TODO: CASTLE-3095
  const initialSearchState: SearchParams = {
    version: '1',
    adults: 1,
    child_ages: [],
    cabinClass: 'ECONOMY',
    legs: [
      {
        origin,
        date: '',
        return_date: null,
      },
    ],
    options: {
      // We have no controls for these currently, so default the options
      include_unpriced_itineraries: true,
      include_mixed_booking_options: true,
    },
    alternativeDestinations: false,
    alternativeOrigins: false,
    state: {},
    prefer_directs: false,
    tripType: 'RETURN',
  };

  return isClient ? (
    <FlightsSearchControlsGC
      onSearch={navigate}
      cldr={cldr}
      culture={culture}
      strings={strings}
      fetchCalendarPrices={fetchCalendarPrices}
      fetchMonthPrices={fetchMonthPrices}
      fetchSuggestions={fetchSuggestions}
      initialSearchState={initialSearchState}
      logger={logger}
      featureDecisions={{
        enableCalendarPricing: true,
        enableMultiCityQuickLink: true,
        enableMwebCalendarOptimisations: false,
      }}
      trackingProps={{
        platform: deviceType,
        screenName: pageType,
      }}
      xSkyscannerClient="falcon"
      // Determine how to set this correctly for a client-side component
      // TODO: CASTLE-3183
      isProductionEnv
    />
  ) : null;
};

export default FlightsSearchControlsGCWrapper;
